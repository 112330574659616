body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Microsoft JhengHei', 'BlinkMacSystemFont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

th.ant-table-cell {
  background-color: #999999 !important;
  color: white !important;
}

.tooltip {
  position: relative;
  /* display: inline-block; */
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #93cb83;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 110%;
  pointer-events: none;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #93cb83 transparent transparent;
  pointer-events: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.content-panel {
  max-height: calc(100vh - 96px);
  min-height: calc(100vh - 96px);
  height: calc(100vh - 96px);
  overflow: hidden;
}

.side-panel-height {
  height: calc(100vh - 96px);
}

.ant-collapse-header {
  padding: 4px 12px !important;
}

:root {
  --color_nav: '#6890B1';
  --color_button: '#67AB9F';
  --color_error: '#CF7796';
}

.text-bar {
  color: var(--color_nav);
}

.text-button {
  color: var(--color_button);
}

.text-error {
  color: var(--color_error);
}

.bg-bar {
  background-color: var(--color_nav);
}

.bg-button {
  background-color: var(--color_button);
}

.bg-error {
  background-color: var(--color_error);
}
